import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Swal from "sweetalert2";

import { editFaqs, getFaqs } from "../../api/apiHandler";

const EditFaqs = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        question: Yup.string().required("Question is required"),
        answer: Yup.string().required("Answer is required"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    useEffect(() => {
        if (id) {
            getFaqs({ id }).then((response) => {
                if (response.code == 1) {
                    setValue("question", response.data[0].question);
                    setValue("answer", response.data[0].answer);
                }
            });
        }
    }, [id]);

    const onSubmit = (data) => {
        editFaqs({ ...data, id }).then((response) => {
            if (response.code == 1) {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                });
                setTimeout(() => {
                    navigate("/faqs");
                }, 2000);
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        });
    };

    return (
        <main className="content">
            <div className="row">
                <div className="col-12 col-xl-12">
                    <div className="card card-body border-0 shadow mb-4 p-5 mt-5">
                        <h1 className="h2 mb-4">Edit Faqs</h1>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div>
                                        <label htmlFor="name">Question</label>
                                        <textarea className="form-control" placeholder="" id="question" rows="2" {...register("question")} />
                                        <div className="invalid__input">
                                                <p>{errors.question?.message}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="answer">Answer</label>
                                        <textarea className="form-control" placeholder="" id="answer" rows="4" {...register("answer")} />
                                        <div className="invalid__input">
                                                <p>{errors.answer?.message}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">
                                    Edit
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button className="btn btn-danger mt-2 animate-up-2" type="button" onClick={() => navigate("/faqs")}>
                                    Cancle
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EditFaqs;
