import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

import { login } from "../../api/apiHandler";
import { loginStoreData } from "../common/RedirectPathMange";

function Login() {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(5, "Password must be at least 5 characters"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    var newData = {
      "email": data.email,
      "password": data.password,
    };
    login(newData).then((res) => {
      if (res.code == 1) {
        loginStoreData(res.data);
        navigate("/");
      } else {
        Swal.fire({
          position: "top",
          icon: "error",
          title: res.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  return (
    <>
      <main>
        <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
          <div className="container">
            <div
              className="row justify-content-center form-bg-image"
              data-background-lg="../../assets/img/illustrations/signin.svg"
            >
              <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="mb-4">
                    <img className="center_img" src={process.env.PUBLIC_URL + "/assets/images/logo.png"} height="90" width="120" alt="Volt Logo" />
                  </div>
                  <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-4">
                      <label htmlFor="email">Your Email</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <svg
                            className="icon icon-xs text-gray-600"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                          </svg>
                        </span>
                        <input
                          id="email"
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          {...register("email")}
                          autofocus
                        />
                      </div>
                      <div className="invalid__input">{errors.email?.message}</div>
                    </div>
                    <div className="form-group">
                      <div className="form-group mb-4">
                        <label htmlFor="password">Your Password</label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon2">
                            <svg
                              className="icon icon-xs text-gray-600"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </span>
                          <input
                            id="password"
                            type="password"
                            placeholder="Password"
                            className="form-control"
                            {...register("password")}
                          />
                        </div>
                        <div className="invalid__input">{errors.password?.message}</div>
                      </div>
                    </div>
                    <div className="d-flex mt-4" style={{ justifyContent: "center" }}>
                      <button type="submit" className="btn btn-gray-800">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Login;
