import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import axios from "axios";

import { editCategory, getcategoryList } from "../../api/apiHandler";

const EditCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        id: Yup.number(),
        category_name: Yup.string().required("Name is required"),
        image: Yup.string()
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors } = formState;

    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [previewImage, setPreviewImage] = useState(null);

    function handleImage(e) {
        setImage(e.target.files[0])
        setImageError("")
    }

    useEffect(() => {
        if (id) {
            getcategoryList({ id: +id }).then((response) => {
                if (response.code == 1) {
                    setValue("category_name", response.data[0].category_name);
                    setValue("image", response.data[0].image);
                    setPreviewImage(response.data[0].image);
                    setValue("id", response.data[0].id)
                } else {
                }
            });
        }
    }, [id]);

    const onSubmit = (data) => {
        const formData = new FormData()
        formData.append('image', image)

        const headers = {
            "api-key": process.env.REACT_APP_API_KEY,
            "accept-language": "en",
            "token": localStorage.getItem("Atoken")
        }

        if (image == null) {
            var params = {
                id: data.id,
                category_name: data.category_name,
                image: data.image
            }
            editCategory(params).then((response) => {
                if (response.code == 1) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setTimeout(() => {
                        navigate("/productCategory")
                    }, 2000)
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        } else {
            axios.post(process.env.REACT_APP_IMAGES_UPLOAD, formData, { headers }).then((res) => {
                var decrypted = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_KEY);
                let res1 = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
                if (res1.code == 1) {
                    var params = {
                        id: data.id,
                        category_name: data.category_name,
                        image: res1.data
                    }
                    editCategory(params).then((response) => {
                        if (response.code == 1) {
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: response.message,
                                toast: true,
                                showConfirmButton: false,
                                timer: 2000,
                            });
                            setTimeout(() => {
                                navigate("/productCategory");
                            }, 2000);
                        } else {
                            Swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: response.message,
                                toast: true,
                                showConfirmButton: false,
                                timer: 2000,
                            });
                        }
                    });
                }
            })
        }
    };

    return (
        <main className="content">
            <div className="row">
                <div className="col-12 col-xl-12">
                    <div className="card card-body border-0 shadow mb-4 p-5 mt-5 col-md-6">
                        <h1 className="h2 mb-4">Edit Category</h1>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="mb-3">
                                    <div>
                                        <label htmlFor="name">Name*</label>
                                        <input className="form-control" id="name" type="text" placeholder="name" {...register("category_name")} />
                                        <div className="invalid__input">
                                            <p>{errors.category_name?.message}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mb-3">
                                    <div className="form-group">
                                        <label htmlFor="profileImage">Category Image*</label>
                                        <input className="form-control" id="profileImage" type="file" accept="image/*" placeholder="" onChange={handleImage} />
                                        <div className="invalid__input">
                                            <p>{imageError}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mb-3">
                                    <div className="form-group">
                                        <img src={`${process.env.REACT_APP_IMAGES}${previewImage}`} alt={previewImage} height={300} width={300} />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">
                                    Edit
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button className="btn btn-danger mt-2 animate-up-2" type="button" onClick={() => navigate("/productCategory")}>
                                    Cancle
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EditCategory;
