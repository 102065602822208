import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CryptoJS from "crypto-js";
import axios from 'axios';
import Swal from 'sweetalert2';

import { createproduct, getcategoryList } from '../../api/apiHandler';

const AddProduct = () => {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState(null);
  const [images, setImages] = useState([]);
  const [imageError, setImageError] = useState("");
  const [imagePreview, setImagePreview] = useState([])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    category_id: Yup.number().typeError('Category is required').required("Category is required"),
    brand: Yup.string().required("Brand name is required"),
    price: Yup.number().typeError('Price is required').required("Price is required"),
    offer_price: Yup.number().typeError('Offer price is required').required("Offer price is required"),
    sku: Yup.string().required("Sku is required"),
    description: Yup.string().required("Description is required"),
    stock: Yup.string(),
    voltage: Yup.string(),
    power: Yup.string(),
    one_step_buttonhole: Yup.string(),
    needle: Yup.string(),
    machine_size: Yup.string(),
    box_size: Yup.string(),
    current: Yup.string(),
    machine_weight: Yup.string(),
    box_weight: Yup.string(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    getcategoryList({ id: 0 }).then((response) => {
      if (response.code == 1) {
        setCategoryList(response.data);
      } else {
        setCategoryList([]);
      }
    });
  }, []);

  function changeStatus(val) {
    const { image } = val;
    // Check if the image is already in the array
    const isImageSelected = images.includes(image);
    // Update the array based on the checked state
    if (isImageSelected) {
      // If checked, remove the image from the array
      setImages((prevImages) =>
        prevImages.filter((selectedImage) => selectedImage !== image)
      );
    } else {
      // If not checked, add the image to the array
      setImages((prevImages) => [...prevImages, image]);
    }
  }

  function handleImage(e) {
    const headers = {
      "api-key": process.env.REACT_APP_API_KEY,
      "accept-language": "en",
      "token": localStorage.getItem("Atoken")
    }
    for (let i = 0; i < e.target.files.length; i++) {
      const formData = new FormData()
      formData.append('image', e.target.files[i]); // 'images[]' key will allow multiple files

      axios.post(process.env.REACT_APP_IMAGES_UPLOAD, formData, { headers }).then((res) => {
        var decrypted = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_KEY);
        let res1 = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        if (res1.code == 1) {
          setImages(prev => [...prev, res1.data])
          setImagePreview(prev => [...prev, { image: res1.data }])
        }
      })
    }
    if (images) {
      setImageError("")
    }
  }

  const onSubmit = (data) => {
    if (images.length >= 1) {
      let params = { ...data, images: images.toString() }
      createproduct(params).then((response) => {
        if (response.code == 1) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
          setTimeout(() => {
            navigate("/product");
          }, 2000);
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } else {
      setImageError("Image is required.")
    }
  };

  return (
    <main className="content">
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card card-body border-0 shadow mb-4 p-5 mt-5">
            <h1 className="h2 mb-4">Add Product</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="name">Name*</label>
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      placeholder="name"
                      {...register("name")}
                    />
                    <div className="invalid__input">
                      <p>{errors.name?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div>
                    <label className="my-1 me-2" for="category_id">Category*</label>
                    <select className="form-select" id="category_id" aria-label="Default select example" {...register("category_id")}>
                      <option selected>Select category</option>
                      {categoryList && categoryList.map(val => {
                        return <option value={val.id} key={val.id}>{val.category_name}</option>
                      })}
                    </select>
                    <div className="invalid__input">
                      <p>{errors.category_id?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="brand">Brand*</label>
                    <input
                      className="form-control"
                      id="brand"
                      type="text"
                      placeholder=""
                      {...register("brand")}
                    />
                    <div className="invalid__input">
                      <p>{errors.brand?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="price">Price*</label>
                    <input
                      className="form-control"
                      id="price"
                      type="number"
                      placeholder=""
                      {...register("price")}
                    />
                    <div className="invalid__input">
                      <p>{errors.price?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="offer_price">Offer price*</label>
                    <input
                      className="form-control"
                      id="offer_price"
                      type="number"
                      placeholder=""
                      {...register("offer_price")}
                    />
                    <div className="invalid__input">
                      <p>{errors.offer_price?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="sku">Sku*</label>
                    <input
                      className="form-control"
                      id="sku"
                      type="text"
                      placeholder=""
                      {...register("sku")}
                    />
                    <div className="invalid__input">
                      <p>{errors.sku?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="description">Description*</label>
                    <textarea
                      className="form-control"
                      id="description"
                      type="text"
                      placeholder=""
                      rows={3}
                      {...register("description")}
                    />
                    <div className="invalid__input">
                      <p>{errors.description?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="stock">Stock*</label>
                    <input
                      className="form-control"
                      id="stock"
                      type="number"
                      {...register("stock")}
                    />
                    <div className="invalid__input">
                      <p>{errors.stock?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="voltage">Voltage*</label>
                    <input
                      className="form-control"
                      id="voltage"
                      type="text"
                      placeholder=""
                      {...register("voltage")}
                    />
                    <div className="invalid__input">
                      <p>{errors.voltage?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="stock">Power*</label>
                    <input
                      className="form-control"
                      id="power"
                      type="text"
                      placeholder=""
                      {...register("power")}
                    />
                    <div className="invalid__input">
                      <p>{errors.power?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="one_step_buttonhole">One step buttonhole*</label>
                    <input
                      className="form-control"
                      id="one_step_buttonhole"
                      type="text"
                      placeholder=""
                      {...register("one_step_buttonhole")}
                    />
                    <div className="invalid__input">
                      <p>{errors.one_step_buttonhole?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="needle">Needle*</label>
                    <input
                      className="form-control"
                      id="needle"
                      type="text"
                      placeholder=""
                      {...register("needle")}
                    />
                    <div className="invalid__input">
                      <p>{errors.needle?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="machine_size">Machine size*</label>
                    <input
                      className="form-control"
                      id="machine_size"
                      type="text"
                      placeholder=""
                      {...register("machine_size")}
                    />
                    <div className="invalid__input">
                      <p>{errors.machine_size?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="box_size">Box size*</label>
                    <input
                      className="form-control"
                      id="box_size"
                      type="text"
                      placeholder=""
                      {...register("box_size")}
                    />
                    <div className="invalid__input">
                      <p>{errors.box_size?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="current">Current*</label>
                    <input
                      className="form-control"
                      id="current"
                      type="text"
                      placeholder=""
                      {...register("current")}
                    />
                    <div className="invalid__input">
                      <p>{errors.current?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="machine_weight">Machine weight*</label>
                    <input
                      className="form-control"
                      id="machine_weight"
                      type="text"
                      placeholder=""
                      {...register("machine_weight")}
                    />
                    <div className="invalid__input">
                      <p>{errors.machine_weight?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="box_weight">Box weight*</label>
                    <input
                      className="form-control"
                      id="box_weight"
                      type="text"
                      placeholder=""
                      {...register("box_weight")}
                    />
                    <div className="invalid__input">
                      <p>{errors.box_weight?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="proImage">Product Image*</label>
                    <input className="form-control" id="proImage" type="file" accept="image/*" placeholder="" multiple onChange={handleImage} />
                    <div className="invalid__input">
                      <p>{imageError}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="form-group">
                    <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto auto' }}>
                      {imagePreview.map((val, index) =>
                        <div style={{ margin: 10 }}>
                          <img src={`${process.env.REACT_APP_IMAGES}${val.image}`} alt={val.image} height={200} width={200} />
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="user-notification-2"
                            checked={images.includes(val.image)}
                            onChange={() => changeStatus(val)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">
                  Add
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button className="btn btn-danger mt-2 animate-up-2" type="button" onClick={() => navigate("/product")}>
                  Cancle
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AddProduct;
