import React, { useEffect, useState } from 'react';

import { dashboard } from '../../api/apiHandler';

function Dashboard() {
    const [categoryCount, setCategoryCount] = useState(0);
    const [productCount, setProductCount] = useState(0);

    useEffect(() => {
        dashboard({}).then((response) => {
            if (response.code == 1) {
                setCategoryCount(response.data[0].total_category);
                setProductCount(response.data[0].total_product);
            }
        })
    }, [])
    return (
        <>
            <main className="content">
                <div className="row">
                    <div className="col-12 col-xl-12">
                        <div className=" mb-4 p-5 mt-5">
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="page-title-box">
                                            <h4 className="page-title">Dashboard</h4>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-xl-3 col-md-6" style={{cursor:"pointer"}}>
                                        <div className="card mini-stat bg-gray-700">
                                            <div className="card-body mini-stat-img">
                                                <div className="mini-stat-icon" style={{color:"white"}}>
                                                    <i className="mdi mdi-cube-outline float-right"></i>
                                                </div>
                                                <div className="text-white">
                                                    <h6 className="text-uppercase mb-3">Total Category</h6>
                                                    <h4 className="mb-2">{categoryCount}</h4>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3 col-md-6" style={{cursor:"pointer"}}>
                                        <div className="card mini-stat bg-gray-700">
                                            <div className="card-body mini-stat-img">
                                                <div className="mini-stat-icon" style={{color:"white"}}>
                                                    <i className="mdi mdi-cube-outline float-right"></i>
                                                </div>
                                                <div className="text-white">
                                                    <h6 className="text-uppercase mb-3">Total Product</h6>
                                                    <h4 className="mb-2">{productCount}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default Dashboard;