import { axiosClient } from "./apiClient";

export function login(data) {
    return axiosClient.post("adminlogin", data);
}
export function logout(data) {
    return axiosClient.post("adminlogout", data);
}
export function changePassword(data) {
    return axiosClient.post("changepassword", data);
}
export function dashboard(data) {
    return axiosClient.post("dashboard", data);
}
export function forgotPassword(data) {
    return axiosClient.post("forgotPassword", data);
}
export function getcategoryList(data) {
    return axiosClient.post("categorylist", data);
}
export function addCategory(data) {
    return axiosClient.post("addcategory", data);
}
export function editCategory(data) {
    return axiosClient.post("editcategory", data);
}
export function deleteCategory(data) {
    return axiosClient.post("deletecategory", data);
}
export function categorystatus(data) {
    return axiosClient.post("categorystatus", data);
}
export function webproductlist(data) {
    return axiosClient.post("webproductlist", data);
}
export function createproduct(data) {
    return axiosClient.post("createproduct", data);
}
export function editproduct(data) {
    return axiosClient.post("editproduct", data);
}
export function deleteproduct(data) {
    return axiosClient.post("deleteproduct", data);
}
export function statuschangeproduct(data) {
    return axiosClient.post("statuschangeproduct", data);
}
export function webcategoryproduct(data) {
    return axiosClient.post("webcategoryproduct", data);
}
export function prodvideolist(data) {
    return axiosClient.post("prodvideolist", data);
}
export function addproductvideo(data) {
    return axiosClient.post("addproductvideo", data);
}
export function editproductvideo(data) {
    return axiosClient.post("editproductvideo", data);
}
export function deleteprodvideo(data) {
    return axiosClient.post("deleteprodvideo", data);
}
export function statusprodvideo(data) {
    return axiosClient.post("statusprodvideo", data);
}
export function addfaqs(data) {
    return axiosClient.post("addfaqs", data);
}
export function getFaqs(data) {
    return axiosClient.post("faqslist", data);
}
export function editFaqs(data) {
    return axiosClient.post("editfaqs", data);
}
export function deleteFaqs(data) {
    return axiosClient.post("deletefaqs", data);
}
export function faqsstatus(data) {
    return axiosClient.post("faqsstatus", data);
}
export function slideShowList(data) {
    return axiosClient.post("slideshowlist", data);
}
export function addSlideShow(data) {
    return axiosClient.post("addslideshow", data);
}
export function deleteSlideShow(data) {
    return axiosClient.post("deleteslideshow", data);
}
export function slideshowstatus(data) {
    return axiosClient.post("slideshowstatus", data);
}
export function getContactList(data) {
    return axiosClient.post("getcontactlist", data);
}
export function imageUpload(data) {
    return axiosClient.post("imageUpload", data);
}
export function updateSocialLinks(data) {
    return axiosClient.post("updatesocialmedia", data);
}
export function websociallink(data) {
    return axiosClient.post("websociallink", data);
}
export function getaboutus(data) {
    return axiosClient.post("getaboutus", data);
}
export function updateaboutus(data) {
    return axiosClient.post("updateaboutus", data);
}
export function contactupdate(data) {
    return axiosClient.post("contactupdate", data);
}
export function getinquirylist(data) {
    return axiosClient.post("getinquirylist", data);
}
export function inquiryupdate(data) {
    return axiosClient.post("inquiryupdate", data);
}
export function getdiscountdetails(data) {
    return axiosClient.post("getdiscountdetails", data);
}
export function editdiscount(data) {
    return axiosClient.post("editdiscount", data);
}
