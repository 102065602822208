import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";

import { changePassword } from "../../api/apiHandler";
import { logOutRedirectCall } from "../common/RedirectPathMange";

const ChangePassword = () => {
	const validationSchema = Yup.object().shape({
		old_password: Yup.string()
			.required('Old password is required')
			.min(5, 'Old password must be at least 5 characters'),
		new_password: Yup.string()
			.required('New password is required')
			.min(5, 'New password must be at least 5 characters'),
	});
	const formOptions = { resolver: yupResolver(validationSchema) };
	const { register, handleSubmit, formState } = useForm(formOptions);
	const { errors } = formState;

	const onSubmit = (data) => {
		changePassword({ ...data, email: localStorage.getItem("Aemail") }).then((response) => {
			if (response.code == 1) {
				Swal.fire({
					position: 'top-end',
					icon: 'success',
					title: response.message,
					toast: true,
					showConfirmButton: false,
					timer: 2000
				})
				setTimeout(() => {
					logOutRedirectCall();
				}, 2000)
			} else {
				Swal.fire({
					position: 'top-end',
					icon: 'error',
					title: response.message,
					toast: true,
					showConfirmButton: false,
					timer: 2000
				})
			}
		})

	};

	return (
		<main className="content">
			<div className="row">
				<div className="col-12 col-xl-12">
					<div className="card card-body border-0 shadow mb-4 p-5 mt-5 col-md-6">
						<h1 className="h2 mb-4">Change Password</h1>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="mb-3">
									<div>
										<label htmlFor="oldpassword">Old Password</label>
										<input className="form-control" placeholder="" id="oldpassword" {...register("old_password")} />
										<div className="invalid__input">
												<p>{errors.old_password?.message}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="mb-3">
									<div className="form-group">
										<label htmlFor="newpassword">New Password</label>
										<input className="form-control" placeholder="" id="newpassword" {...register("new_password")} />
										<div className="invalid__input">
												<p>{errors.new_password?.message}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="mt-4">
								<button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">
									Change
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</main>
	);
};

export default ChangePassword;
