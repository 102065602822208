import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";

import { updateSocialLinks, websociallink } from "../../api/apiHandler";

const SocialLinks = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    facebook: Yup.string()
      .required('facebook link is required'),
    instagram: Yup.string()
      .required('instagram link is required'),
    linkedin: Yup.string()
      .required('linkedin link is required'),
    twitter: Yup.string()
      .required('twitter link is required')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset, setValue } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    Swal.fire({
      title: "Please wait...",
      didOpen: () => {
          Swal.showLoading();
      },
  })
    websociallink({}).then((response) => {
      Swal.close();
      const res = response.data[0];
      if (response.code == 1) {
        setValue("facebook", res.facebook);
        setValue("instagram", res.instagram);
        setValue("linkedin", res.linkedin);
        setValue("twitter", res.twitter);
      }
    });
  }, []);

  const onSubmit = (data) => {
    updateSocialLinks(data).then((response) => {
      if (response.code == 1) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
        setTimeout(() => {
          reset();
          navigate("/")
        }, 2000)
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    })
  };

  return (
    <main className="content">
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card card-body border-0 shadow mb-4 p-5 mt-5">
            <h1 className="h2 mb-4">Edit Social Links</h1>
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="facebook">Facebook</label>
                    <input className="form-control" placeholder="" id="facebook" {...register("facebook")} />
                    <div className="invalid__input">
                      <center>
                        <p>{errors.facebook?.message}</p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="instagram">Instagram</label>
                    <input className="form-control" placeholder="" id="instagram" {...register("instagram")} />
                    <div className="invalid__input">
                      <center>
                        <p>{errors.instagram?.message}</p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="linkedin">Youtube</label>
                    <input className="form-control" placeholder="" id="linkedin" {...register("linkedin")} />
                    <div className="invalid__input">
                      <center>
                        <p>{errors.linkedin?.message}</p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="twitter">Twitter</label>
                    <input className="form-control" placeholder="" id="twitter" {...register("twitter")} />
                    <div className="invalid__input">
                      <center>
                        <p>{errors.twitter?.message}</p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">
                  Edit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SocialLinks;
