import React, { useState, useEffect } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Swal from 'sweetalert2';

import { getinquirylist, inquiryupdate } from "../../api/apiHandler";

const Inquiry = () => {
    const [inquiryList, setInquiryList] = useState(null);

    const showMessage = (msg) => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    useEffect(() => {
        Swal.fire({
            title: "Please wait...",
            didOpen: () => {
                Swal.showLoading();
            },
        })
        getinquirylist({ id: 0 }).then((response) => {
            Swal.close();
            if (response.code == 1) {
                setInquiryList(response.data);
            } else {
                setInquiryList([]);
            }
        });
    }, []);

    useEffect(() => {
        if (!inquiryList) {
            return;
        }
        $("#mangeUser").DataTable({
            pagingType: "full_numbers",
            pageLength: 5,
            processing: true,
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: ["copy", "csv", "print"],
            bDestroy: true,
        });
    }, [inquiryList]);

    function renderStatusUiText(item) {
        return (
            item.is_reply == "P" ? <input
                className="form-check-input"
                type="checkbox"
                id="user-notification-2"
                onChange={() => changeStatus(item)}
            /> : <input
                className="form-check-input"
                type="checkbox"
                id="user-notification-2"
                checked={true}
            />
        )
    }

    function changeStatus(item) {
        inquiryupdate({ id: item.id }).then((response) => {
            if (response.code == 1) {
                showMessage(response.message)
                $("#mangeUser")
                    .DataTable()
                    .row("#inquiryId" + item.id)
                    .remove()
                    .draw(false);
            }else{
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        });
    }

    return (
        <main className="content">
            <br />
            <div className="card card-body border-0 shadow table-wrapper table-responsive">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                    </div>
                </div>
                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }}>Status</th>
                            <th style={{ textAlign: "center" }}>ID</th>
                            <th style={{ textAlign: "center" }}>Name</th>
                            <th style={{ textAlign: "center" }}>Email</th>
                            <th style={{ textAlign: "center" }}>Phone no</th>
                            <th style={{ textAlign: "center" }}>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inquiryList &&
                            inquiryList.map((item, index) => {
                                return (
                                    <tr id={"inquiryId" + item.id} key={"inquiryId" + item.id}>
                                        <td style={{ textAlign: "center" }}>{renderStatusUiText(item)}</td>
                                        <td style={{ textAlign: "center" }}>{item.id}</td>
                                        <td style={{ textAlign: "center" }}>{item.name}</td>
                                        <td style={{ textAlign: "center" }}>{item.email}</td>
                                        <td style={{ textAlign: "center" }}>{item.phone}</td>
                                        <td style={{ textAlign: "center" }}>{item.message}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th style={{ textAlign: "center" }}>Status</th>
                            <th style={{ textAlign: "center" }}>ID</th>
                            <th style={{ textAlign: "center" }}>Name</th>
                            <th style={{ textAlign: "center" }}>Email</th>
                            <th style={{ textAlign: "center" }}>Phone no</th>
                            <th style={{ textAlign: "center" }}>Message</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </main>
    );
};

export default Inquiry;
