import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { getaboutus } from "../../api/apiHandler";

const AboutUs = () => {
	const navigate = useNavigate();
	const [aboutUs,setAboutUs] = useState("");

	useEffect(() => {
		Swal.fire({
            title: "Please wait...",
            didOpen: () => {
                Swal.showLoading();
            },
        })
		getaboutus({}).then((response) => {
			Swal.close();
			if (response.code == 1) {
				setAboutUs(response.data[0].content);
			} else {
				setAboutUs([]);
			}
		});
	}, []);

	return (
		<main className="content">
			<div className="row">
				<div className="col-12 col-xl-12">
					<div className="card card-body border-0 shadow mb-4 p-5 mt-5 col-md-6">
						<h1 className="h2 mb-4">About Us</h1>
						<form>
							<div className="row">
								<div className="mb-3">
									<div style={{ padding: '10px' }}>
										<label htmlFor="name">{aboutUs}</label>
									</div>
								</div>
								<div className="mt-4">
									<button className="btn btn-gray-800 mt-2 animate-up-2" type="submit" onClick={() => navigate("/aboutus/edit")}>
										Edit
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</main>
	);
};

export default AboutUs;
