import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import axios from "axios";

import { editdiscount, getdiscountdetails } from "../../api/apiHandler";

const EditDiscountBanner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    discount: Yup.number().typeError('Dicount percenatge is required').required("Dicount percenatge is required  "),
    bannerHeading: Yup.string().required("Discount header is required"),
    image: Yup.string()
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, setValue } = useForm(formOptions);
  const { errors } = formState;

  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const [previewImage, setPreviewImage] = useState(null);

  function handleImage(e) {
    setImage(e.target.files[0])
    setImageError("")
  }

  useEffect(() => {
    if (id) {
      getdiscountdetails({ id: +id }).then((response) => {
        if (response.code == 1) {
          setValue("id", response.data[0].id)
          setValue("discount", response.data[0].discount);
          setValue("bannerHeading", response.data[0].heading);
          setValue("image", response.data[0].poster);
          setPreviewImage(response.data[0].poster);
        } else {
        }
      });
    }
  }, [id]);

  const onSubmit = (data) => {
    const formData = new FormData()
    formData.append('image', image)

    const headers = {
      "api-key": process.env.REACT_APP_API_KEY,
      "accept-language": "en",
      "token": localStorage.getItem("Atoken")
    }

    if (image == null) {
      var params = {
        id: data.id,
        discount: data.discount,
        heading: data.bannerHeading,
        poster: data.image
      }
      editdiscount(params).then((response) => {
        if (response.code == 1) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.data.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
          setTimeout(() => {
            navigate("/discountBanner")
          }, 2000)
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
    } else {
      axios.post(process.env.REACT_APP_IMAGES_UPLOAD, formData, { headers }).then((res) => {
        var decrypted = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_KEY);
        let res1 = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        if (res1.code == 1) {
          var params = {
            id: data.id,
            discount: data.discount,
            heading: data.bannerHeading,
            poster: res1.data
          }
          editdiscount(params).then((response) => {
            if (response.code == 1) {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: response.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000,
              });
              setTimeout(() => {
                navigate("/discountBanner");
              }, 2000);
            } else {
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: response.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
        }
      })
    }
  };

  return (
    <main className="content">
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card card-body border-0 shadow mb-4 p-5 mt-5 col-md-6">
            <h1 className="h2 mb-4">Edit Discount Banner</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="mb-3">
                  <div>
                    <label htmlFor="bannerHeading">Discount Header*</label>
                    <input className="form-control" id="bannerHeading" type="text" placeholder="header" {...register("bannerHeading")} />
                    <div className="invalid__input">
                      <p>{errors.bannerHeading?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-3">
                  <div>
                    <label htmlFor="bannerHeading">Discount Percentage*</label>
                    <input className="form-control" id="discount" type="text" placeholder="percentage" {...register("discount")} />
                    <div className="invalid__input">
                      <p>{errors.discount?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-3">
                  <div className="form-group">
                    <label htmlFor="profileImage">Banner Image*</label>
                    <input className="form-control" id="profileImage" type="file" accept="image/*" placeholder="" onChange={handleImage} />
                    <div className="invalid__input">
                      <p>{imageError}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-3">
                  <div className="form-group">
                    <img src={`${process.env.REACT_APP_IMAGES}${previewImage}`} alt={previewImage} height={300} width={300} />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">
                  Edit
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button className="btn btn-danger mt-2 animate-up-2" type="button" onClick={() => navigate("/discountBanner")}>
                  Cancle
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EditDiscountBanner;
