import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from 'antd';

const Header = () => {

    const items = [
        {
            key: '1',
            label: (
                <Link to="/changePassword" rel="noopener noreferrer" href="https://www.antgroup.com">
                    Change Password
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link to="/logout" rel="noopener noreferrer">
                    LogOut
                </Link>
            ),
        },
    ];
    return (
        <main className="content" style={{ backgroundColor: "white" }}>
            <nav className="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2">
                <div className="container-fluid px-0">
                    <div className="d-flex justify-content-between w-100" id="navbarSupportedContent">
                        <div className="d-flex align-items-center">
                            <form className="navbar-search form-inline" id="navbar-search-main">
                                <div className="input-group input-group-merge search-bar">
                                    &nbsp;
                                </div>
                            </form>
                        </div>
                        <ul className="navbar-nav align-items-center">
                            <Dropdown menu={{
                                items,
                            }}
                                placement="bottomLeft"
                            >
                                <li className="nav-item dropdown ms-lg-3" style={{ cursor: "pointer" }}>
                                    <div className="media d-flex align-items-center">
                                        <img className="avatar rounded-circle" alt="placeholder" src={process.env.PUBLIC_URL + "/assets/images/profile.jpg"} />
                                        <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                                            <span className="mb-0 font-small fw-bold text-gray-900">Admin</span>
                                        </div>
                                    </div>
                                </li>
                            </Dropdown>
                        </ul>
                    </div>
                </div>
            </nav>
        </main>
    );
};

export default Header;
