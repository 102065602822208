import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { editproductvideo, getcategoryList, prodvideolist, webcategoryproduct, webproductlist } from '../../api/apiHandler';
import Swal from 'sweetalert2';
import { responsiveArray } from 'antd/es/_util/responsiveObserver';

const EditProductVideo = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	// const [categoryList, setCategoryList] = useState(null);
	const [productList, setProductList] = useState(null);
	const [productId, setProductId] = useState(null);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Name is required"),
		video: Yup.string().required("Video is required"),
		// category_id: Yup.number().typeError('Category is required').required("Category is required"),
		product_id: Yup.number().typeError('Product is required').required("Product is required"),
	});
	const formOptions = { resolver: yupResolver(validationSchema) };
	const { register, handleSubmit, setValue, formState } = useForm(formOptions);
	const { errors } = formState;

	useEffect(() => {
		if (id) {
			prodvideolist({ id: +id }).then((response) => {
				if (response.code == 1) {
					setValue("name", response.data[0].name);
					setValue("video", response.data[0].video);
					setValue("product_id", response.data[0].product_id);
					setProductId(response.data[0].product_id)
				}
			});
		}
	}, [id]);

	// useEffect(() => {
	// 	getcategoryList({ id: 0 }).then((response) => {
	// 		if (response.code == 1) {
	// 			setCategoryList(response.data);
	// 		} else {
	// 			setCategoryList([]);
	// 		}
	// 	});
	// }, []);

	useEffect(() => {
		webproductlist({ id: 0 }).then(response => {
			if (response.code == 1) {
				setProductList(response.data);
			} else {
				setProductList([]);
			}
		})
	}, [])

	// const handleCategoryChange = (e) => {
	// 	webcategoryproduct({ id: +e.target.value }).then((response) => {
	// 		if (response.code == 1) {
	// 			setProductList(response.data);
	// 		} else {
	// 			setProductList([]);
	// 		}
	// 	});
	// }



	const onSubmit = (data) => {
		const params = {
			id,
			"product_id": data.product_id,
			"name": data.name,
			"video": data.video
		}
		editproductvideo(params).then((response) => {
			if (response.code == 1) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: response.message,
					toast: true,
					showConfirmButton: false,
					timer: 2000,
				});
				setTimeout(() => {
					navigate("/productVideo");
				}, 2000);
			} else {
				Swal.fire({
					position: "top-end",
					icon: "error",
					title: response.message,
					toast: true,
					showConfirmButton: false,
					timer: 2000,
				});
			}
		});
	};

	return (
		<main className="content">
			<div className="row">
				<div className="col-12 col-xl-12">
					<div className="card card-body border-0 shadow mb-4 p-5 mt-5">
						<h1 className="h2 mb-4">Edit Product Video</h1>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-6 mb-3">
									{/* <div>
										<label className="my-1 me-2" for="category_id">Category*</label>
										<select className="form-select" id="category_id" aria-label="Default select example" onClick={handleCategoryChange} {...register("category_id")} defaultValue={10}>
											<option value="" disabled>Select category</option>
											{categoryList && categoryList.map(val => {
												return <option value={val.id} key={val.id}>{val.category_name}</option>
											})}
										</select>
										<div className="invalid__input">
											<p>{errors.category_id?.message}</p>
										</div>
									</div> */}
									<div>
										<label htmlFor="product_id">Product*</label>
										<select className="form-select" id="product_id" aria-label="Default select example" {...register("product_id")} defaultValue="option2">
											<option selected>Select Product</option>
											{productList && productList.map(val => {
												return <option value={val.id} key={val.id} selected={productId == val.id ? true : false}>{val.name}</option>
											})}
										</select>
										<div className="invalid__input">
											<p>{errors.product_id?.message}</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 mb-3">
								<div className="form-group">
										<label htmlFor="name">Name*</label>
										<input
											className="form-control"
											id="name"
											type="text"
											placeholder=""
											{...register("name")}
										/>
										<div className="invalid__input">
											<p>{errors.name?.message}</p>
										</div>
									</div>
								</div>

							</div>
							<div className="row">
								<div className="col-md-6 mb-3">
								<div className="form-group">
										<label htmlFor="video">Video*</label>
										<input
											className="form-control"
											id="video"
											type="text"
											placeholder=""
											{...register("video")}
										/>
										<div className="invalid__input">
											<p>{errors.video?.message}</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 mb-3">
									
								</div>
							</div>

							<div className="mt-4">
								<button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">
									Edit
								</button>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<button className="btn btn-danger mt-2 animate-up-2" type="button" onClick={() => navigate("/productVideo")}>
									Cancle
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</main>
	);
};

export default EditProductVideo;
