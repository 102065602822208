import React, { useState, useEffect } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { getdiscountdetails } from "../../api/apiHandler";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Swal from "sweetalert2";

const DiscountBanner = () => {
	const navigate = useNavigate();
	const [discountBanner, setDiscountBanner] = useState(null);

	useEffect(() => {
		Swal.fire({
            title: "Please wait...",
            didOpen: () => {
                Swal.showLoading();
            },
        })
		getdiscountdetails({ id: 0 }).then((response) => {
			Swal.close();
			if (response.code == 1) {
				setDiscountBanner(response.data)
			} else {
				setDiscountBanner([])
			}
		});
	}, []);

	useEffect(() => {
		if (!discountBanner) {
			return;
		}
		$("#manageUser").DataTable({
			pagingType: "full_numbers",
			pageLength: 5,
			processing: true,
			order: [[0, "desc"]],
			dom: "Bfrtip",
			buttons: ["copy", "csv", "print"],
			"bDestroy": true,
		});
	}, [discountBanner]);

	const showMessage = (msg) => {
		Swal.fire({
			position: "top-end",
			icon: "success",
			title: msg,
			toast: true,
			showConfirmButton: false,
			timer: 1500,
		});
	};

	const editDiscountBanner = (item) => {
        navigate(`/discountBanner/edit/${item.id}`);
    };

	return (
		<main className="content">
			<br />
			<div className="card card-body border-0 shadow table-wrapper table-responsive">
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
					<div className="d-block mb-4 mb-md-0">
					</div>
					<div className="btn-toolbar mb-2 mb-md-0">
					</div>
				</div>
				<table id="manageUser" className="table table-hover dataTable js-exportable">
					<thead>
						<tr>
							<th style={{ textAlign: "center" }}>ID</th>
							<th style={{ textAlign: "center" }}>Discount Percentage</th>
							<th style={{ textAlign: "center" }}>Discount Title</th>
							<th style={{ textAlign: "center" }}>Image</th>
							<th style={{ textAlign: "center" }}>Action</th>
						</tr>
					</thead>
					<tfoot>
						<tr>
							<th style={{ textAlign: "center" }}>ID</th>
							<th style={{ textAlign: "center" }}>Discount Percentage</th>
							<th style={{ textAlign: "center" }}>Discount Title</th>
							<th style={{ textAlign: "center" }}>Image</th>
							<th style={{ textAlign: "center" }}>Action</th>
						</tr>
					</tfoot>
					<tbody>
						{discountBanner &&
							discountBanner.map((item, index) => {
								return (
									<tr id={"productVideoId" + item.id} key={"productVideoId" + item.id}>
										<td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.id}</td>
										<td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.discount}</td>
										<td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.heading}</td>
										<td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                            <img src={`${process.env.REACT_APP_IMAGES}${item.poster}`} alt={item.poster} height={100} width={100} />
                                        </td>
										<td style={{ textAlign: "center", verticalAlign: "middle" }}>
											{
												<Tooltip title="Edit">
													<IconButton
														onClick={() => {
															editDiscountBanner(item);
														}}
													>
														<FeatherIcon icon="edit" width="18" />
													</IconButton>
												</Tooltip>
											}
										</td>
									</tr>
								);
							})}
					</tbody>

				</table>
			</div>
		</main>
	);
}

export default DiscountBanner
