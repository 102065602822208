import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import axios from "axios";

import { addSlideShow } from "../../api/apiHandler";

const AddSlideShow = () => {
	const navigate = useNavigate();
	const validationSchema = Yup.object().shape({
		pos: Yup.number().typeError('pos is required').required("pos is required")
	});
	const formOptions = { resolver: yupResolver(validationSchema) };
	const { register, handleSubmit, formState } = useForm(formOptions);
	const { errors } = formState;

	const [image, setImage] = useState(null);
	const [imageError, setImageError] = useState("");

	function handleImage(e) {
		setImage(e.target.files[0])
		setImageError("")
	}

	const onSubmit = (data) => {
		if (image) {
			const formData = new FormData()
			formData.append('image', image)
			const headers = {
				"api-key": process.env.REACT_APP_API_KEY,
				"accept-language": "en",
				"token": localStorage.getItem("Atoken")
			}

			axios.post(process.env.REACT_APP_IMAGES_UPLOAD, formData, { headers }).then((res) => {
				var decrypted = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_KEY);
				let res1 = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
				if (res1.code == 1) {
					var params = {
						...data,
						image: res1.data
					}
					addSlideShow(params).then((response) => {
						if (response.code == 1) {
							Swal.fire({
								position: "top-end",
								icon: "success",
								title: response.message,
								toast: true,
								showConfirmButton: false,
								timer: 2000,
							});
							setTimeout(() => {
								navigate("/slideshow");
							}, 2000);
						} else {
							Swal.fire({
								position: "top-end",
								icon: "error",
								title: response.message,
								toast: true,
								showConfirmButton: false,
								timer: 2000,
							});
						}
					});
				}
			})
		} else {
			setImageError("Image is required.")
		}
	};

	return (
		<main className="content">
			<div className="row">
				<div className="col-12 col-xl-12">
					<div className="card card-body border-0 shadow mb-4 p-5 mt-5">
						<h1 className="h2 mb-4">Add Slide Show</h1>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-6 mb-3">
									<div>
										<label htmlFor="pos">Pos(position of image)*</label>
										<input className="form-control" id="pos" type="text" placeholder="pos" {...register("pos")} />
										<div className="invalid__input">
												<p>{errors.pos?.message}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label htmlFor="profileImage">Image*</label>
										<input className="form-control" id="profileImage" type="file" accept="image/*" placeholder="" onChange={handleImage} />
										<div className="invalid__input">
												<p>{imageError}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="mt-4">
								<button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">
									Add
								</button>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<button className="btn btn-danger mt-2 animate-up-2" type="button" onClick={() => navigate("/slideshow")}>
									Cancle
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</main>
	);
};

export default AddSlideShow;
