import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2'

import { forgotPassword } from '../../api/apiHandler';

function Forgotpassword() {
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const onSubmit = (data) => {
        forgotPassword({ "email": data.email }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 1) {
                Swal.fire({
                    position: 'top',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 3000
                })
                setTimeout(() => {
                    navigate("/")
                }, 3000)

            } else {
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })

    }
    return (
        <div className="wrapper-page">
            <div className="card">
                <div className="card-body">
                    <div className="text-center" style={{ height: 50 }}>
                        <Link to="/" className="logo logo-admin"><img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} height="32" alt="logo" /></Link>
                    </div>
                    <div className="p-3">
                        <h4 className="text-muted font-18 mb-3 text-center">Forgot Password</h4>
                        <div className="alert alert-info" role="alert">
                            Enter your Email and instructions will be sent to you!
                        </div>
                        <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label htmlFor="useremail">Email</label>
                                <input type="email" {...register('email')} className="form-control" id="useremail" placeholder="Enter Email" />
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>
                            <div className="form-group row m-t-20">
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Send Mail</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Forgotpassword;