import "./App.css";
import Sidebar from "./components/sidebar/Sidebar";
import AddCategory from "./pages/category/AddCategory";
import Dashboard from "./pages/dashboard/Dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddProduct from "./pages/product/AddProduct";
import Header from "./components/header/Header";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Product from "./pages/product/Product";
import Category from "./pages/category/Category";
import Faqs from "./pages/Faqs/Faqs";
import AddFaqs from "./pages/Faqs/AddFaqs";
import EditFaqs from "./pages/Faqs/EditFaqs";
import ContactList from "./pages/contact-list/ContactList";
import SocialLinks from "./pages/social-links/SocialLinks";
import EditCategory from "./pages/category/EditCategory";
import Logout from "./pages/auth/Logout";
import ChangePassword from "./pages/auth/ChangePassword";
import SlideShow from "./pages/slide-show/SlideShow";
import AddSlideShow from "./pages/slide-show/AddSlideShow";
import AboutUs from "./pages/about-us/AboutUs";
import EditAboutus from "././pages/about-us/EditAboutus"
import ProductVideo from './pages/product-video/ProductVideo';
import EditProductVideo from './pages/product-video/EditProductVideo';
import EditProduct from './pages/product/EditProduct';
import Inquiry from './pages/inquiry/Inquiry';
import AddProductVideo from './pages/product-video/AddProductVideo';
import DiscountBanner from './pages/discountBanner/DiscountBanner';
import EditDiscountBanner from './pages/discountBanner/EditDiscountBanner';

function App() {

    if (!localStorage.getItem("AisLogin", false)) {
        return (
            <div className="App">
                <BrowserRouter basename={"/"}>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                    </Routes>
                </BrowserRouter>
            </div>
        );
    } else {
        return (
            <>
                <BrowserRouter basename={"/"}>
                    <Sidebar />
                    <Header />
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/changePassword" element={<ChangePassword />} />
                        <Route path="/productCategory" element={<Category />} />
                        <Route path="/productCategory/add" element={<AddCategory />} />
                        <Route path="/productCategory/edit/:id" element={<EditCategory />} />
                        <Route path="/product" element={<Product />} />
                        <Route path="/product/add" element={<AddProduct />} />
                        <Route path="/product/edit/:id" element={<EditProduct />} />
                        <Route path="/productVideo" element={<ProductVideo/>} />
                        <Route path="/productVideo/add" element={<AddProductVideo/>} />
                        <Route path="/productVideo/edit/:id" element={<EditProductVideo/>} />
                        <Route path="/faqs" element={<Faqs />} />
                        <Route path="/faqs/add" element={<AddFaqs />} />
                        <Route path="/faqs/edit/:id" element={<EditFaqs />} />
                        <Route path="/contactuslist" element={<ContactList />} />
                        <Route path="/inquirylist" element={<Inquiry />} />
                        <Route path="/sociallinks" element={<SocialLinks />} />
                        <Route path="/aboutus" element={<AboutUs />} />
                        <Route path="/aboutus/edit" element={<EditAboutus />} />
                        <Route path="/slideshow" element={<SlideShow />} />
                        <Route path="/slideshow/add" element={<AddSlideShow />} />
                        <Route path="/discountBanner" element={<DiscountBanner />} />
                        <Route path="/discountBanner/edit/:id" element={<EditDiscountBanner />} />
                        <Route path="/logout" element={<Logout />} />
                    </Routes>
                </BrowserRouter>
            </>
        );
    }
}

export default App;
