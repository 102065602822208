import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import axios from "axios";

import { addCategory } from "../../api/apiHandler";

const AddCategory = () => {
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        category_name: Yup.string().required("Name is required")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");

    function handleImage(e) {
        setImage(e.target.files[0])
        setImageError("")
    }

    const onSubmit = (data) => {
        if (image) {
            const formData = new FormData()
            formData.append('image', image)
            const headers = {
                "api-key": process.env.REACT_APP_API_KEY,
                "accept-language": "en",
                "token": localStorage.getItem("Atoken")
            }
            axios.post(process.env.REACT_APP_IMAGES_UPLOAD, formData, { headers }).then((res) => {
                var decrypted = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_KEY);
                let res1 = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
                if (res1.code == 1) {
                    var params = {
                        ...data,
                        image: res1.data
                    }
                    addCategory(params).then((response) => {
                        if (response.code == 1) {
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: response.message,
                                toast: true,
                                showConfirmButton: false,
                                timer: 2000,
                            });
                            setTimeout(() => {
                                navigate("/productCategory");
                            }, 2000);
                        } else {
                            Swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: response.message,
                                toast: true,
                                showConfirmButton: false,
                                timer: 2000,
                            });
                        }
                    });
                }
            })
        } else {
            setImageError("Image is required.")
        }
    };

    return (
        <main className="content">
            <div className="row">
                <div className="col-12 col-xl-12">
                    <div className="card card-body border-0 shadow mb-4 p-5 mt-5 col-md-6">
                        <h1 className="h2 mb-4">Add Category</h1>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="mb-3">
                                    <div>
                                        <label htmlFor="name">Name*</label>
                                        <input className="form-control" id="name" type="text" placeholder="name" {...register("category_name")} />
                                        <div className="invalid__input">
                                                <p>{errors.category_name?.message}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mb-3">
                                    <div className="form-group">
                                        <label htmlFor="profileImage">Category Image*</label>
                                        <input className="form-control" id="profileImage" type="file" accept="image/*" placeholder="" onChange={handleImage} />
                                        <div className="invalid__input">
                                                <p>{imageError}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">
                                    Add
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button className="btn btn-danger mt-2 animate-up-2" type="button" onClick={() => navigate("/productCategory")}>
                                    Cancle
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AddCategory;
