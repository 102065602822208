import React, { useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';

import { logOutRedirectCall } from '../common/RedirectPathMange';

function Logout() {
    useEffect(() => {
        logOutRedirectCall();
    }, [])

    return (
        <div>
        </div>
    )
}

export default Logout;