import React, { useState, useEffect } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from 'react-dom/server'

import { categorystatus, getcategoryList } from "../../api/apiHandler";
import { deleteCategory } from "../../api/apiHandler";

const Category = () => {
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState(null);

    useEffect(() => {
        Swal.fire({
            title: "Please wait...",
            didOpen: () => {
                Swal.showLoading();
            },
        })
        getcategoryList({ id: 0 }).then((response) => {
            Swal.close();
            if (response.code == 1) {
                setCategoryList(response.data);
            } else {
                setCategoryList([]);
            }
        });
    }, []);

    const showMessage = (msg) => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    useEffect(() => {
        if (!categoryList) {
            return;
        }
        $("#mangeUser").DataTable({
            pagingType: "full_numbers",
            pageLength: 5,
            processing: true,
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: ["copy", "csv", "print"],
            bDestroy: true,
        });
    }, [categoryList]);

    function renderStatusUiText(item) {
        return (
            item.is_active == "1" ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    function changeStatus(item,position) {
        let status = (item.is_active == "0") ? 1 : 0
        categorystatus({ id: item.id}).then((response) => {
            if (response.code == 1) {
                showMessage(response.message)
                categoryList[position].is_active = status
                var data = renderToStaticMarkup(renderStatusUiText(categoryList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                $('#mangeUser').DataTable().row("#categoryId" + item.id).cell("#categoryStatus" + item.id).data(data).draw(false);
            }
        }); 
    }

    const deleteCategoryHandler = (categoryId) => {
        Swal.fire({
            title: "Are you sure you want to delete?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#02C9D6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCategory({ id: categoryId }).then((response) => {
                    if (response.code == 1) {
                        $("#mangeUser")
                            .DataTable()
                            .row("#categoryId" + categoryId)
                            .remove()
                            .draw(false);
                        showMessage(response.message);
                    } else {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: response.message,
                            toast: true,
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    }

                });
            }
        });
    }

    const editCategory = (item) => {
        navigate(`/productCategory/edit/${item.id}`);
    };

    return (
        <main className="content">
            <br />
            <div className="card card-body border-0 shadow table-wrapper table-responsive">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            </ol>
                        </nav>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <NavLink to="/productCategory/add" className="btn btn-sm btn-gray-800 d-inline-flex align-items-center">
                            <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                            </svg>
                        </NavLink>
                    </div>
                </div>
                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }}>ID</th>
                            <th style={{ textAlign: "center" }}>Name</th>
                            <th style={{ textAlign: "center" }}>Image</th>
                            <th style={{ textAlign: "center" }}>Status</th>
                            <th style={{ textAlign: "center" }}>Status Action</th>
                            <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categoryList &&
                            categoryList.map((item, index) => {
                                return (
                                    <tr id={"categoryId" + item.id} key={"categoryId" + item.id}>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.id}</td>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.category_name}</td>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                            <img src={`${process.env.REACT_APP_IMAGES}${item.image}`} alt={item.id} height={100} width={100} />
                                        </td>
                                        <td id={"categoryStatus" + item.id} style={{ textAlign: "center", verticalAlign: "middle" }}>
                                            {renderStatusUiText(item)}
                                        </td>
                                        <td id={"categoryStatus" + item.id} style={{ textAlign: "center", verticalAlign: "middle" }}>
                                            <Tooltip title="Change Status">
                                                <IconButton onClick={() => { changeStatus(item, index) }}>
                                                    <FeatherIcon icon="repeat" width="18" />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                            {
                                                <Tooltip title="Edit">
                                                    <IconButton
                                                        onClick={() => {
                                                            editCategory(item);
                                                        }}
                                                    >
                                                        <FeatherIcon icon="edit" width="18" />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        onClick={() => {
                                                            deleteCategoryHandler(item.id);
                                                        }}
                                                    >
                                                        <FeatherIcon icon="trash-2" width="18" />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th style={{ textAlign: "center" }}>ID</th>
                            <th style={{ textAlign: "center" }}>Name</th>
                            <th style={{ textAlign: "center" }}>Image</th>
                            <th style={{ textAlign: "center" }}>Status</th>
                            <th style={{ textAlign: "center" }}>Status Action</th>
                            <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </main>
    );
};

export default Category;
