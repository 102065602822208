import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const handleClick = () => {
    setSidebar(false);
  };

  return (
    <>
      <nav className="navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
        <Link className="navbar-brand me-lg-5" to="/">
          Sun Marketing
        </Link>
        <div className="d-flex align-items-center">
          <button className="navbar-toggler d-lg-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setSidebar((prev) => !prev)}>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
      <nav id="sidebarMenu" className={`sidebar d-lg-block bg-gray-800 text-white collapse ${sidebar && "show"}`} data-simplebar>
        <div className='nav-item' style={{ margin: "auto", display: "flex", alignItems: "center" }}>
        </div>
        <div className="sidebar-inner px-4 pt-3">
          <ul className="nav flex-column pt-3 pt-md-0">
            <li className="nav-item pl-5">
              <span className="sidebar-text" style={{ fontSize: "19px" }}>SUN MARKETING</span>
            </li>
            <br />

            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/" className="nav-link">
                <span className="sidebar-icon">
                  <svg className="icon icon-xs me-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" x="0px" y="0px" viewBox="0 0 50 50">
                    <path d="M 25 1.0507812 C 24.7825 1.0507812 24.565859 1.1197656 24.380859 1.2597656 L 1.3808594 19.210938 C 0.95085938 19.550938 0.8709375 20.179141 1.2109375 20.619141 C 1.5509375 21.049141 2.1791406 21.129062 2.6191406 20.789062 L 4 19.710938 L 4 46 C 4 46.55 4.45 47 5 47 L 19 47 L 19 29 L 31 29 L 31 47 L 45 47 C 45.55 47 46 46.55 46 46 L 46 19.710938 L 47.380859 20.789062 C 47.570859 20.929063 47.78 21 48 21 C 48.3 21 48.589063 20.869141 48.789062 20.619141 C 49.129063 20.179141 49.049141 19.550938 48.619141 19.210938 L 25.619141 1.2597656 C 25.434141 1.1197656 25.2175 1.0507812 25 1.0507812 z M 35 5 L 35 6.0507812 L 41 10.730469 L 41 5 L 35 5 z"></path>
                  </svg>
                </span>
                <span className="sidebar-text">Dashboard</span>
              </NavLink>
            </li>

            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/productCategory" className="nav-link">
                <span className="sidebar-icon">
                  <svg version="1.1" fill="currentColor" className="icon icon-xs me-2" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 122.566" xmlSpace="preserve"><g><path d="M3.78,66.082h47.875c2.045,0,3.717,1.988,3.717,4.414v46.479 c0,2.43-1.671,4.416-3.717,4.416H3.78c-2.043,0-3.717-1.986-3.717-4.416V70.496C0.063,68.07,1.737,66.082,3.78,66.082L3.78,66.082z M71.224,0H119.1c2.046,0,3.717,1.986,3.717,4.415v46.479c0,2.429-1.671,4.413-3.717,4.413H71.224 c-2.045,0-3.714-1.984-3.714-4.413V4.415C67.51,1.986,69.179,0,71.224,0L71.224,0z M3.714,0h47.878 c2.045,0,3.717,1.986,3.717,4.415v46.479c0,2.429-1.671,4.413-3.717,4.413H3.714C1.671,55.307,0,53.323,0,50.894V4.415 C0,1.986,1.671,0,3.714,0L3.714,0z M71.287,67.26h47.876c2.043,0,3.717,1.986,3.717,4.416v46.479c0,2.426-1.674,4.412-3.717,4.412 H71.287c-2.045,0-3.717-1.986-3.717-4.412V71.676C67.57,69.246,69.242,67.26,71.287,67.26L71.287,67.26z" /></g></svg>
                </span>
                <span className="sidebar-text">Category</span>
              </NavLink>
            </li>

            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/product" className="nav-link">
                <span className="sidebar-icon">
                  <svg className="icon icon-xs me-2" fill="currentColor" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 122.25" xmlSpace="preserve"><g><path d="M122.57,29.25l0.31,62.88c0.01,3.28-2.05,6.1-5,7.29l0.01,0.01l-54.64,22.09c-0.99,0.4-2.05,0.6-3.12,0.6 c-0.11,0-0.22,0-0.33-0.01c-0.47,0.08-0.95,0.13-1.42,0.13c-1.06,0-2.11-0.21-3.08-0.62L4.94,100.46l0-0.01 C2.03,99.22-0.01,96.32,0,92.94l0.3-62.08c-0.04-0.66,0-1.33,0.12-1.99c0.02-0.95,0.22-1.88,0.58-2.76 c0.84-2.04,2.47-3.55,4.42-4.33l0-0.01L57.98,0.6c2.14-0.86,4.44-0.77,6.4,0.07l52.47,18.97c3.14,1.13,5.13,3.96,5.27,7.01 C122.41,27.49,122.57,28.37,122.57,29.25L122.57,29.25z M51.51,108.46l0.39-54.77L9.82,35.5L8.93,90.49L51.51,108.46L51.51,108.46 L51.51,108.46z M113.58,35.5L66.55,53.7l0.37,54.71l46.94-17.54L113.58,35.5L113.58,35.5L113.58,35.5z" /></g></svg>
                </span>
                <span className="sidebar-text">Product</span>
              </NavLink>
            </li>

            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/productVideo" className="nav-link">
                <span className="sidebar-icon">
                  <svg className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 102.55 122.88" xmlSpace="preserve"><g><path className="st0" d="M102.55,122.88H0V0h77.66l24.89,26.43V122.88L102.55,122.88z M19.36,42.93h41.87c1.9,0,3.46,1.55,3.46,3.45 v12.8l21.95-11.18v36.39L64.69,72.07v16.18c0,1.9-1.56,3.46-3.46,3.46H19.36c-1.9,0-3.46-1.55-3.46-3.46V46.38 C15.91,44.48,17.46,42.93,19.36,42.93L19.36,42.93z M37.22,55.43v23.78l11.89-11.89L37.22,55.43L37.22,55.43z M96.13,115.98V30.44 H72.49V5.91H6.51v110.07H96.13L96.13,115.98z" /></g></svg>
                </span>
                <span className="sidebar-text">Product Video</span>
              </NavLink>
            </li>

            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/slideshow" className="nav-link">
                <span className="sidebar-icon">
                  <svg id="Layer_1" className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 118.96"><path d="M3.27,0H119.61a3.26,3.26,0,0,1,3.27,3.27V115.69a3.26,3.26,0,0,1-3.27,3.27H3.27A3.26,3.26,0,0,1,0,115.69V3.27A3.26,3.26,0,0,1,3.27,0ZM116.34,81.49H84.1v30.93h32.24V81.49Zm0-37.48H84.1V75h32.24V44ZM84.1,6.54V37.47h32.24V6.54Zm-6.54,0h-71V112.42h71V6.54Z" /></svg>
                </span>
                <span className="sidebar-text">Slide Show</span>
              </NavLink>
            </li>

            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/discountBanner" className="nav-link">
                <span className="sidebar-icon">
                  <svg id="Layer_1" className="icon icon-xs me-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">
                    <g><g><g><path fill="currentColor" d="M131.2,35.6c-3.6,0.5-8.1,2-12.3,4.2c-3.1,1.6-7.4,5.7-54.8,53.1c-56.8,56.7-54,53.6-54,59.8c0,6-0.6,5.3,31.3,37.1C73,221.5,72,220.6,78,220.6c6.2,0,2.9,3,58.2-52.3c31.5-31.5,51.2-51.5,52.7-53.5c1.3-1.8,3.1-5.1,3.9-7.3c2.5-6.3,2.8-9.5,2.3-21.5c-0.2-5.8-0.4-12.8-0.5-15.6l-0.1-5.1l2.6-0.1c3.5-0.2,9.8,1.1,13.3,2.8c4,1.9,8.3,5.6,11.3,9.5c4.3,5.6,10.2,18.9,11.5,25.6c0.4,2,0.3,2,7.5,0.2c6.6-1.6,6.3-0.8,3.3-10.2c-3.8-11.8-8.9-20.9-15.3-27.7c-15.3-16.1-37.1-17.6-63.4-4.6c-10.4,5.2-21.9,13.3-23.2,16.2c-1.8,4.4,1.2,9,5.9,9c2.3,0,2.1,0.1,10.2-5.7c2.4-1.7,4.6-3,4.8-2.9c0.7,0.4,0,5.6-1.2,8.3c-1.4,3.2-5.4,7-8.5,8c-1.3,0.4-3.7,0.8-5.5,0.8c-13.3,0-19.7-16.4-10.1-25.7c10.7-10.4,34-21.6,49.5-23.8c2.4-0.3,4.4-0.8,4.5-1.1c0.3-1-3-4.1-5.9-5.6l-3.1-1.5l-18.5-0.7c-10.2-0.3-21.2-0.7-24.4-0.8C136.5,35.3,132.7,35.4,131.2,35.6z M91.4,101l1.3,1.2l3.3,35.5c1.8,19.6,3.3,36.4,3.3,37.3c0,4-4.3,6-7.1,3.2l-1.3-1.3l-3.3-35.2c-1.8-19.4-3.3-36.2-3.3-37.3C84.3,100.4,88.4,98.5,91.4,101z M125.5,117.9c3.5,1.6,6.9,5,8.5,8.5c1.1,2.4,1.3,3.4,1.3,7.4s-0.2,5-1.3,7.4c-1.6,3.5-5,6.9-8.5,8.5c-2.4,1.1-3.4,1.3-7.1,1.3c-3.8,0-4.7-0.2-7.4-1.4c-3.7-1.7-7-5-8.8-8.9c-1.1-2.3-1.3-3.4-1.3-6.9c0-3.4,0.2-4.6,1.3-6.8c2.2-4.8,6.1-8.3,11.2-9.9C116.6,116,122.2,116.4,125.5,117.9z M70.3,129c7.6,2.4,12.2,8.6,12.2,16.5c0,6.8-3.6,12.7-9.6,15.7c-2.6,1.3-3.2,1.5-7.6,1.5c-4.4,0-5.1-0.1-7.6-1.4c-10.7-5.4-13.1-19.5-4.7-27.9C57.6,128.5,63.9,126.9,70.3,129z" /><path fill="currentColor" d="M116.5,126.9c-3,0.8-5.1,3.7-5.1,7c0,2.8,1.5,5,4,6.2c2.6,1.2,3.4,1.2,6,0c2.4-1.2,4-3.7,4-6.2C125.4,129.4,120.8,125.8,116.5,126.9z" /><path fill="currentColor" d="M61.3,139.6c-4.5,3-4.1,9.8,0.7,12.2c6.1,3.1,12.3-3.3,9.4-9.6C69.8,138.9,64.5,137.5,61.3,139.6z" /></g></g></g>
                  </svg>
                </span>
                <span className="sidebar-text">Discount Banner</span>
              </NavLink>
            </li>

            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/sociallinks" className="nav-link">
                <span className="sidebar-icon">
                  <svg className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 99.58"><path className="cls-1" d="M8.45,0H99.16a8.47,8.47,0,0,1,8.44,8.45V34.92H107A24.52,24.52,0,0,0,99.9,36V26.33H8.05V81.47a2.32,2.32,0,0,0,2.34,2.34H58.21a24.93,24.93,0,0,0,1.35,8H8.45A8.47,8.47,0,0,1,0,83.33V8.45A8.47,8.47,0,0,1,8.45,0ZM44.71,46.1a4.25,4.25,0,1,1,6.06-6l11.76,12a4.23,4.23,0,0,1,0,6L51,69.79a4.25,4.25,0,1,1-6.06-6l4.49-4.57-22-.09a4.25,4.25,0,1,1,.07-8.5l21.76.08L44.71,46.1ZM94.58,59.23a3.3,3.3,0,0,1-4.67-4.65l5.88-5.89a15.87,15.87,0,0,1,22.46,22.44L112.34,77a3.3,3.3,0,0,1-4.64-4.68l5.88-5.88a9.33,9.33,0,0,0,0-13.11l-.16-.17a9.31,9.31,0,0,0-6.4-2.53,9.21,9.21,0,0,0-6.55,2.7l-5.89,5.88Zm6.6,1.7a3.3,3.3,0,0,1,4.66,4.66l-17,17a3.3,3.3,0,1,1-4.66-4.66l17-16.95ZM95.53,84.5a3.31,3.31,0,0,1,4.68,4.67L94.43,95A15.92,15.92,0,0,1,72.06,95l-.28-.29A15.89,15.89,0,0,1,72,72.5l5.77-5.78a3.3,3.3,0,0,1,4.67,4.67l-5.77,5.78a9.33,9.33,0,0,0-.17,13l.17.16a9.33,9.33,0,0,0,13.11,0l5.77-5.78Zm-4.41-75a4.17,4.17,0,1,1-4.18,4.17A4.17,4.17,0,0,1,91.12,9.5Zm-28.28,0a4.17,4.17,0,1,1-4.17,4.17A4.17,4.17,0,0,1,62.84,9.5ZM77,9.5a4.17,4.17,0,1,1-4.17,4.17A4.17,4.17,0,0,1,77,9.5Z" /></svg>
                </span>
                <span className="sidebar-text">Social Links</span>
              </NavLink>
            </li>
            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/contactuslist" className="nav-link">
                <span className="sidebar-icon">
                  <svg className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 114.35 122.88" xmlSpace="preserve"><g><path d="M5.361,0h95.059c2.949,0,5.361,2.413,5.361,5.361v15.083h7.787 c0.43,0,0.781,0.349,0.781,0.778V37.81c0,0.22-0.182,0.399-0.402,0.399h-8.166v14.348h7.787c0.43,0,0.781,0.349,0.781,0.777v16.589 c0,0.22-0.182,0.398-0.402,0.398h-8.166v14.347h7.787c0.43,0,0.781,0.349,0.781,0.779v16.589c0,0.22-0.182,0.399-0.402,0.399 h-8.166v15.082c0,2.948-2.412,5.361-5.361,5.361H5.361c-2.948,0-5.361-2.413-5.361-5.361V5.361C0,2.413,2.414,0,5.361,0L5.361,0z M14.269,99.61V87.268c6.604-2.939,26.802-8.38,27.769-16.45c0.204-1.84-4.091-8.929-5.08-12.267 c-1.236-1.977-1.909-2.683-1.88-5.127c0.019-1.368,0.05-2.715,0.245-4.039c0.245-1.683,0.199-1.735,1.079-3.098 c0.921-1.408,0.522-6.556,0.522-8.506c0-19.342,33.9-19.353,33.9,0c0,2.456-0.564,6.951,0.764,8.867 c0.656,0.928,0.531,1.052,0.752,2.2c0.277,1.495,0.311,3.028,0.33,4.577c0.025,2.444-0.635,3.15-1.879,5.127 c-1.201,3.502-5.775,10.126-5.377,12.116c1.453,7.38,20.193,12.332,26.1,14.956L91.51,99.61H14.269L14.269,99.61z" /></g></svg>
                </span>
                <span className="sidebar-text">Contact Us</span>
              </NavLink>
            </li>

            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/inquirylist" className="nav-link">
                <span className="sidebar-icon">
                  <svg className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 55" id="inquiry"><g fill="currentColor" fillRule="evenodd"><g fill="currentColor" transform="translate(-489 -394)"><g transform="translate(489 394)"><path d="M56.905.743H5.095C2.657.743.524 2.876.524 5.314v34.134c0 2.438 2.133 4.571 4.571 4.571H5.4c.914 0 1.524.61 1.524 1.524v4.876c0 1.22.61 2.438 1.524 3.352.914.915 2.133 1.22 3.047 1.22 1.22 0 2.438-.61 3.048-1.22l9.143-9.142c.304-.305.61-.61.914-.61h32.305c2.438 0 4.571-2.133 4.571-4.571V5.314c0-2.438-2.133-4.571-4.571-4.571zm1.524 38.705c0 .914-.61 1.523-1.524 1.523h-32c-1.22 0-2.438.61-3.353 1.22l-9.142 9.142c-.915.915-1.524.61-2.134 0-.305-.304-.305-.61-.305-1.219v-4.876c0-2.438-2.133-4.571-4.571-4.571h-.305c-.914 0-1.524-.61-1.524-1.524V5.314c0-.914.61-1.524 1.524-1.524h51.81c.914 0 1.524.61 1.524 1.524v34.134z"></path><path d="M37.4 19.029c0 3.047-2.133 5.485-4.876 6.4v1.219c0 .914-.61 1.523-1.524 1.523s-1.524-.61-1.524-1.523v-2.743c0-.915.61-1.524 1.524-1.524 1.829 0 3.352-1.524 3.352-3.352 0-1.829-1.523-3.353-3.352-3.353s-3.352 1.524-3.352 3.353c0 .914-.61 1.523-1.524 1.523s-1.524-.61-1.524-1.523c0-3.658 3.048-6.4 6.4-6.4 3.657 0 6.4 3.047 6.4 6.4zM31 34.267c-.914 0-1.524-.61-1.524-1.524v-.61c0-.914.61-1.523 1.524-1.523s1.524.609 1.524 1.523v.61c0 .914-.61 1.524-1.524 1.524z"></path></g></g></g></svg>
                </span>
                <span className="sidebar-text">Inquiry</span>
              </NavLink>
            </li>

            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/faqs" className="nav-link">
                <span className="sidebar-icon">
                  <svg className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 116.6"><path d="M17.72,45.43V21.12h16.8v5.32H24.21v4.18h9.28v5.31H24.21v9.5ZM16.67,0h66.5A16.7,16.7,0,0,1,99.84,16.67V51.36A16.7,16.7,0,0,1,83.17,68H45.46L20.16,89.78a2.81,2.81,0,0,1-4.62-2.31L16.89,68h-.22A16.7,16.7,0,0,1,0,51.36V16.67A16.7,16.7,0,0,1,16.67,0Zm93,24.65a16.42,16.42,0,0,1,13.26,16V77.85a16.37,16.37,0,0,1-16.33,16.32H106L107.34,114h0a2.46,2.46,0,0,1-4,2L77.89,93.53H38.26L54.74,76.77H95.82a13.9,13.9,0,0,0,13.85-13.86V25.75c0-.37,0-.74,0-1.1Zm-26.46-19H16.67a11.1,11.1,0,0,0-11,11.05V51.36a11.1,11.1,0,0,0,11.05,11h3.42a2.8,2.8,0,0,1,2.6,3L21.6,81.17l20.87-18a2.79,2.79,0,0,1,2-.81H83.16a11.1,11.1,0,0,0,11-11V16.67a11.1,11.1,0,0,0-11-11Zm-43,39.81h-7L41,21.12h8.87l7.88,24.31h-7L45.53,27.82h-.19L40.12,45.43Zm-1.31-9.59H52v4.94H38.81V35.84Zm29.35.57h5L75,38.74l3.08,3.55,4,5H76.37l-2.84-3.37-1.92-2.81-3.45-4.65Zm13.91-3.13a13.84,13.84,0,0,1-1.56,6.84,10.36,10.36,0,0,1-4.19,4.21,12.78,12.78,0,0,1-11.77,0,10.45,10.45,0,0,1-4.18-4.23,13.83,13.83,0,0,1-1.54-6.81,13.89,13.89,0,0,1,1.54-6.84,10.42,10.42,0,0,1,4.18-4.22,12.82,12.82,0,0,1,11.77,0,10.45,10.45,0,0,1,4.19,4.22,13.78,13.78,0,0,1,1.56,6.84Zm-6.68,0a10.35,10.35,0,0,0-.57-3.68,4.75,4.75,0,0,0-1.66-2.29,5.17,5.17,0,0,0-5.43,0,4.83,4.83,0,0,0-1.67,2.29,10.59,10.59,0,0,0-.56,3.68A10.63,10.63,0,0,0,66.06,37a4.8,4.8,0,0,0,1.67,2.28,5.12,5.12,0,0,0,5.43,0A4.72,4.72,0,0,0,74.82,37a10.38,10.38,0,0,0,.57-3.68Z" /></svg>
                </span>
                <span className="sidebar-text">Faqs</span>
              </NavLink>
            </li>

            <li role="separator" className="dropdown-divider border-gray-600"></li>

            <li className="nav-item" onClick={handleClick}>
              <NavLink to="/aboutus" className="nav-link">
                <span className="sidebar-icon">
                  <svg fill="currentColor" className="icon icon-xs me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.19 122.88"><path d="M17.16 0h82.72a3.32 3.32 0 013.31 3.31v92.32c-.15 2.58-3.48 2.64-7.08 2.48H15.94c-4.98 0-9.05 4.07-9.05 9.05s4.07 9.05 9.05 9.05h80.17v-9.63h7.08v12.24c0 2.23-1.82 4.05-4.05 4.05H16.29C7.33 122.88 0 115.55 0 106.59V17.16C0 7.72 7.72 0 17.16 0zm3.19 13.4h2.86c1.46 0 2.66.97 2.66 2.15v67.47c0 1.18-1.2 2.15-2.66 2.15h-2.86c-1.46 0-2.66-.97-2.66-2.15V15.55c.01-1.19 1.2-2.15 2.66-2.15z" fillRule="evenodd" clipRule="evenodd" /></svg>
                </span>
                <span className="sidebar-text">About Us</span>
              </NavLink>
            </li>

          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
