export function loginRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/" 
//    window.open(path,'mywindow').focus()
    window.location.href = path;
  }
export function logOutRedirectCall(){
    localStorage.removeItem("AisLogin");
    localStorage.removeItem("Atoken");
    localStorage.removeItem("Aname");
    localStorage.removeItem("Aid");
    localStorage.removeItem("Aemail");
    localStorage.removeItem("Acountry_code");
    localStorage.removeItem("Aphone");
    localStorage.removeItem("Arole");
    loginRedirectCall()
  }
 
export function loginStoreData(data){
  loginRedirectCall()
  localStorage.setItem("AisLogin",true);
  localStorage.setItem("Atoken",data.token);
  localStorage.setItem("Aname",data.name);
  localStorage.setItem("Aid",data.id);
  localStorage.setItem("Aemail",data.email);
  localStorage.setItem("Acountry_code",data.country_code);
  localStorage.setItem("Aphone",data.phone);
  localStorage.setItem("Arole",data.role);
  loginRedirectCall()
}
