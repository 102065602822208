import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";

import { getaboutus, updateaboutus } from "../../api/apiHandler";

const EditAboutus = () => {
	const navigate = useNavigate();

	const validationSchema = Yup.object().shape({
		text: Yup.string().required("About Us is required")
	});
	const formOptions = { resolver: yupResolver(validationSchema) };
	const { register, handleSubmit, formState, setValue } = useForm(formOptions);
	const { errors } = formState;

	useEffect(() => {
		getaboutus({}).then((response) => {
			if (response.code == 1) {
				setValue("text", response.data[0].content);
			}
		});
	}, []);

	const onSubmit = (data) => {
		updateaboutus(data).then((response) => {
			if (response.code == 1) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: response.message,
					toast: true,
					showConfirmButton: false,
					timer: 2000,
				});
				setTimeout(() => {
					navigate("/aboutus");
				}, 2000);
			} else {
				Swal.fire({
					position: "top-end",
					icon: "error",
					title: response.message,
					toast: true,
					showConfirmButton: false,
					timer: 2000,
				});
			}
		});
	};

	return (
		<main className="content">
			<div className="row">
				<div className="col-12 col-xl-12">
					<div className="card card-body border-0 shadow mb-4 p-5 mt-5 col-md-6">
						<h1 className="h2 mb-4">Edit About Us</h1>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="mb-3">
									<div style={{ padding: '10px' }}>
										<label htmlFor="updateaboutus">About Us</label>
										<textarea className="form-control" placeholder="" id="updateaboutus" rows="6" {...register("text")} />
										<div className="invalid__input">
											<p>{errors.text?.message}</p>
										</div>
									</div>
								</div>
								<div className="mt-4">
									<button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">
										Edit
									</button>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<button className="btn btn-danger mt-2 animate-up-2" type="button" onClick={() => navigate("/aboutus")}>
										Cancle
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</main>
	);
};

export default EditAboutus;
